import * as React from 'react';
import ReduxProvider from 'ReduxProvider';
import { connect } from 'react-redux';
import { setUserData } from 'Client/common/actions/userActions';
import CreateCloudProjectProvidersSelection from './CreateCloudProjectProvidersSelection__JsBridge.res.js';

const mapDispatchToProps = dispatch => ({
  reduxfunction: {
    setUserData: userData => {
      dispatch(setUserData(userData));
    },
  },
});

const CreateCloudProjectProvidersSelectionContainer = connect(
  null,
  mapDispatchToProps,
)(CreateCloudProjectProvidersSelection);

export default ({ context, ...props }) => (
  <ReduxProvider>
    <CreateCloudProjectProvidersSelectionContainer
      projectId={props.projectId}
      providers={props.providers}
      railsContext={context}
      cloudType={props.cloudType}
      selectedProvidersIds={props.selectedProvidersIds}
      status={props.status}
      reduxfunction={props.reduxfunction}
    />
  </ReduxProvider>
);
